














































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import ImgView from '@/components/ImgView/index.vue'
import EditTimeModal from './editTime-modal.vue'
import TipModal from '@/views/common/tip-modal.vue'
import { getDicts, getDictName } from '@/utils/dict'
import { getExportExcel } from '@/utils/export'
import { Select, Option } from 'element-ui'
Vue.use(Select)
Vue.use(Option)

@Component({
  components: {
    Table,
    Pagination,
    ImgView,
    EditTimeModal,
    TipModal
  }
})
export default class VehicleList extends Vue {
  $constants: any
  listLoading = false
  isOpenTipModal = false
  isOpenBatchTipModal = false
  deleteCarId = ''
  // 字典数据
  // 车辆类型
  vehicleTypeDicts: any = []
  tableHeader = [
    {
      key: 'carNo',
      name: '车牌号',
      width: '8%'
    },
    {
      key: 'carOwner',
      name: '车主',
      width: '8%'
    },
    {
      key: 'phone',
      name: '联系电话',
      width: ''
    },
    {
      key: 'carType',
      name: '车辆类型',
      width: '7%',
      isSpecial: true
    },
    {
      key: 'houseInfo',
      name: '房屋信息',
      width: '7%'
    },
    {
      key: 'carPlaceNo',
      name: '车位绑定信息',
      width: '9%'
    },
    {
      key: 'dueDate',
      name: '到期时间',
      width: '9%',
      isSpecial: true
    },
    {
      key: 'status',
      name: '失效状态',
      width: '7%',
      isSpecial: true
    },
    {
      key: 'licensePhoto',
      name: '行驶证照片',
      width: '8%',
      isSpecial: true
    },
    {
      key: 'remark',
      name: '备注',
      width: '10%',
      remark: true
    },
    {
      key: 'opt',
      name: '操作',
      width: '10%',
      isSpecial: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  carType = ''
  searchModel = {
    key: 'carNo',
    value: null
  }
  imgUrl = ''
  isOpenModal = false
  // 传入修改到期时间弹框中的数据
  editTimeModalData = {}

  // 批量操作 按钮 显隐控制
  batchOpt = false
  currentHoverBtn = ''
  //是否清除表格勾选
  isClearChecked = false
  // 是否有表格勾选
  tableChoose = false
  // 勾选的list
  checkedList = [] as any

  async beforeCreate() {
    // 获取相关字典
    // 车辆类型
    this.vehicleTypeDicts = await getDicts('CAR_TYPE')
  }

  created() {
    if (this.$route.query.houseInfo) {
      this.searchModel.key = 'houseInfo'
      ;(this.searchModel.value as any) = this.$route.query.houseInfo
    }
    this.getVehicleList()
  }

  searchFn() {
    this.listQuery.page = 1
    this.getVehicleList()
  }

  // 获取车辆列表
  getVehicleList() {
    this.listLoading = true
    this.$api.car
      .getCarList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        carType: this.carType,
        [this.searchModel.key]: this.searchModel.value
      })
      .then((res: any) => {
        this.tableData = []
        const data = res.data
        this.tableData = data.data.map((v: any) => {
          v['isChecked'] = false
          return v
        })
        this.checkedList = []
        this.isClearChecked = true
        this.total = data.total
        this.listLoading = false
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }

  // btn移入移出控制样式
  hoverbtn(kind: string) {
    this.currentHoverBtn = kind
  }
  defaultbtn() {
    this.currentHoverBtn = ''
  }
  //取消操作
  cancelOpt() {
    this.batchOpt = false
    this.tableChoose = false
    this.isClearChecked = false
    this.tableData.forEach((v: any) => {
      v['isChecked'] = false
      return v
    })
  }

  // 获取当前已选
  getCheckedList(list: any) {
    this.checkedList = list
  }

  // 批量导出
  doExport() {
    if (!this.checkedList.length) {
      this.$message.error('请选择车辆')
      return
    }
    const ids: any = []
    this.checkedList.forEach((x: any) => {
      ids.push(x.id)
    })
    const p = {
      exportType: 'car',
      carType: this.carType,
      ids: ids,
      [this.searchModel.key]: this.searchModel.value
    }
    getExportExcel(p, '车辆导出.xlsx')
  }

  // 批量删除
  batchDelete() {
    if (!this.checkedList.length) {
      this.$message.error('请选择车辆')
      return
    }
    const ids: any = []
    this.checkedList.forEach((x: any) => {
      ids.push(x.id)
    })
    this.$api.car.batchDeleteCar(ids).then((res: any) => {
      if (res.data && res.data.success) {
        this.isOpenBatchTipModal = false
        this.$message.success('车辆删除成功')
        this.getVehicleList()
        this.cancelOpt()
      }
    })
  }

  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }

  // 关闭对话框
  closeDialog() {
    this.isOpenModal = false
    this.isOpenTipModal = false
    this.isOpenBatchTipModal = false
    this.deleteCarId = ''
  }
  // 操作对话框后统一回调
  afterOpt() {
    this.getVehicleList()
    this.closeDialog()
  }

  // 编辑车辆
  toEdit(id: any) {
    this.$router.push({
      path: '/cars/add-vehicle',
      query: {
        carNo: id
      }
    })
  }
  // 新增车辆
  toAdd() {
    this.$router.push('/cars/add-vehicle')
  }

  // 删除车辆
  deleteCar() {
    this.$api.car.deleteCar(this.deleteCarId).then((res: any) => {
      if (res.data.success) {
        this.isOpenTipModal = false
        this.$message.success('车辆删除成功')
        this.getVehicleList()
        this.deleteCarId = ''
      }
    })
  }
  // 跳转到车辆进出详情
  toHistory(carNo: string) {
    this.$router.push({
      path: '/cars/record-history',
      query: {
        carNo
      }
    })
  }
}
