























/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from 'moment'
import Dialog from '@/components/Dialog/index.vue'
import { DatePicker } from 'element-ui'
Vue.use(DatePicker)

@Component({
  components: {
    Dialog
  }
})
export default class extends Vue {
  @Prop({ default: false }) private isShow!: boolean
  @Prop({ default: false }) private loading!: boolean
  @Prop() private data!: any

  date = this.data.dueDate
  option = {
    disabledDate: (time: any) => {
      return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000
    }
  }
  closeDialog() {
    this.$emit('close', '')
  }
  dialogConfirm() {
    if (this.date) {
      this.$api.car
        .editDueDate({
          dueDate: moment(this.date).format('YYYY-MM-DD 00:00:00'),
          id: this.data.id
        })
        .then((res: any) => {
          const data = res.data
          if (data.success) {
            this.$message.success('更新成功')
            this.$emit('optSuccess')
          } else {
            this.$message.error(data.msg)
          }
        })
        .catch(() => {
          this.$message.error('修改失败')
        })
    } else {
      this.$message.warning('请先选择日期')
    }
  }
}
